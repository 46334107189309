(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('PaginationSelect', factory);
    } else {
        // Browser globals
        root.PaginationSelect = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
        'use strict';

        function Select(element) {
            this.container = element;

            if (!this.container) return;

            this.form = this.container.querySelector('form');
            this.select = this.container.querySelector('select');

            if (!this.form || !this.select) return;

            this.onchange = this.onchange.bind(this);
            this.select.addEventListener('change', this.onchange);

            this.container.classList.remove('-no-js');
        }

        Select.prototype = {
            onchange: function (event) {
                var value = this.select.value;
                var pattern = this.container.dataset.urlPattern; // data-url-pattern

                if (!pattern) {
                    return this.form.submit();
                }

                var match = (pattern.match('[[' + this.select.name + ']]'));

                if (!match) {
                    return this.form.submit();
                }

                window.location = pattern.replace('[[' + this.select.name + ']]', value);
            }
        }

        return Select;
}));