; (function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('CowardForm', ['ModalWindow', 'jQuery'], factory);
    } else {
        // Browser globals
        root.CowardForm = factory(root.ModalWindow, root.jQuery);
    }
}(typeof self !== 'undefined' ? self : this, function (Modal, $) {
        'use strict';

        function Form(element) {
            if (element.tagName !== 'FORM') {
                throw new TypeError('Coward Form component expects a FORM element, got ' + element.tagName + ' instead')
            }

            this.container = element;
            this.confirm = this.container.querySelector('[data-confirm]');
            this.cancel = this.container.querySelector('[data-cancel]')

            if (!this.confirm || !this.cancel) return;

            var modal = this.container.querySelector('[data-modal]');

            if (modal) {
                this.modal = new Modal(modal);
            }

            this.onsubmit = this.onsubmit.bind(this);
            this.onconfirm = this.onconfirm.bind(this);
            this.oncancel = this.oncancel.bind(this);

            this.container.addEventListener('submit', this.onsubmit);
            this.confirm.addEventListener('click', this.onconfirm);
            this.cancel.addEventListener('click', this.oncancel);
        }

        Form.prototype = {
            onsubmit: function (event) {
                event.preventDefault();

                if (typeof ($) === 'function' && typeof($.fn.validate) === 'function') {
                    if (!$(this.container).valid()) {
                        return;
                    }
                }

                if (this.modal) this.modal.open();
            },
            onconfirm: function (event) {
                event.preventDefault();
                this.container.submit(event);
            },
            oncancel: function (event) {
                event.preventDefault();
                if (this.modal) {
                    this.modal.close();
                }
            }
        }

        return Form;
}));